import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'

import news5pic1 from '../assets/images/news5pic1.jpg'
import news5pic2 from '../assets/images/new5pic2.jpg'
import news5pic3 from '../assets/images/news5pic3.jpg'
import news5pic4 from '../assets/images/news5pic4.jpg'
import news5pic5 from '../assets/images/news5pic5.jpg'
import news5pic6 from '../assets/images/news5pic6.jpg'
import ios from '../assets/images/app-store.svg'
import google from '../assets/images/google-play.svg'
const News5 = props => (
  <Layout>
    <Helmet>
      <title>三健客 3musclers - 【三健客三週年慶🏋🏽‍好康三 重 送 🎉】</title>
      <meta name="description" content="三健客三週年慶‍ 好康三重送" />
    </Helmet>

    <div id="main" className="alt">
      <section id="one">
        <div className="inner">
          <header className="major">
            <h1>三健客三週年慶<br/>好康三重送 🎉</h1>
          </header>
          <div className="box alt">
            <div className="grid-wrapper">
              <div className="col-4">
                <span className="image main">
                  <img src={news5pic1} alt="" />
                </span>
              </div>
              <div className="col-4">
                <span className="image main">
                  <img src={news5pic2} alt="" />
                </span>
              </div>
              <div className="col-4">
                <span className="image main">
                  <img src={news5pic3} alt="" />
                </span>
              </div>
            </div>
            <div className="grid-wrapper">
              <div className="col-4">
                <span className="image main">
                  <img src={news5pic4} alt="" />
                </span>
              </div>
              <div className="col-4">
                <span className="image main">
                  <img src={news5pic5} alt="" />
                </span>
              </div>
              <div className="col-4">
                <span className="image main">
                  <img src={news5pic6} alt="" />
                </span>
              </div>
            </div>
          </div>
          <p>
            不知不覺大家也陪三健客走過三個年頭了！
            <br />
            三健客要在這重要的日子好好回饋大家的支持💪🏽
            <br />
            接下來的三重好康即將來襲，你準備好了嗎！
          </p>
          <h2>👏🏼好康第一重——三健客親自指導團課特別班🏋</h2>
          <p>
            蓋伊、蔡藥師、波波有在教課嗎？」
            <br />
            三週年慶 🎉三健客終於破天荒合體開課啦🔥
            <br />
            三健客親自指導團課特別班——只送不賣！
            <br />
            🤜按讚 三健客 3musclers-永和店 並公開分享此篇貼文
            <br />
            <iframe
              src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2F3muscler%2Fposts%2F179441460378049&show_text=false&width=552&appId=3212226998840909&height=498"
              width="552"
              height="498"
              style={{
                border: 'none',
                overflow: 'hidden',
              }}
              scrolling="no"
              frameborder="0"
              allowTransparency="true"
              allow="encrypted-media"
            ></iframe>
            <br />
            🤜底下留言tag 2個你愛健身的朋友，並告訴我們你喜歡三健客的原因！
            <br />
            （三健客都會認真看，大家快趁機告白！）
            <br />
            🤜抽3組（你和你tag的兩個朋友為一組）可以免費上「三健客親自指導特別團課🏋」
            <br />
            （團課以基礎動作為主，較適合健身初學者參加喔！）
            <br />
            🤜三週年慶活動時間：即日起——11/6（五）的23:59止
          </p>
          <h2>👏🏼好康第二重——買教練課送高純度魚油1罐（原價NT$1600）</h2>
          <p>
            為什麼要上教練課？
            <br />
            💪 專業身體諮詢＆評估
            <br />
            💪 一小時客製化私人教練教學
            <br />
            💪 送市價200元的Inbody測量
            <br />
            現在購買一對一課程8堂只要NT$11200🔥
            <br />
            再送「 藥師健生活 高純度魚油」一罐（原價NT$1600）！
          </p>
          <h2>👏🏼好康第三重——教練體驗課NT$800再送三健客限定手機殼</h2>
          <p>
            從沒上過教練課有點心驚驚嗎？
            <br />
            別擔心！三健客為你準備好了體驗課💪🏽
            <br />
            兩次體驗機會，讓你找到最適合的教練！
            <br />
            💪🏼體驗1對1的教練課只要NT$800🔥
            <br />
            💪🏼再送市價200元的Inbody測量
            <br />
            💪🏼三週年慶限量加碼贈「 三健客限定手機殼」！
            <br />
            （＊限量100名） 此時不健更待何時？趕快私訊約起來！
            <br />
            跟著三健客一起健生活🏋🏽‍🏋🏽‍🏋🏽‍
            <br />
				<br/>
            <h2>📣三週年慶活動時間📣</h2>
            <h4>即日起——11/6（五）的23:59止</h4>
          </p>
        </div>
      </section>
    </div>
  </Layout>
)

export default News5
